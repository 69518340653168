<div class="modulos-destaque">
      <div *ngFor="let m of modulos;let last=lats;" [ngSwitch]="m.tag">
            <div *ngSwitchCase="'blog'">  
                  <blog-destaque [data]="m"></blog-destaque> 
            </div>
            <div *ngSwitchCase="'destino-destaque'">   
                  <modulo-destinos [data]="m"></modulo-destinos> 
            </div>
            <div *ngSwitchCase="'produto-destaque-categoria'">   
                  <modulo-produto-categoria-destaque [data]="m"></modulo-produto-categoria-destaque>
                  <div class="linha-divisor"></div> 
            </div>
            <div *ngSwitchCase="'produto-destaque-classificacao'">   
                  <modulo-produto-classificacao-destaque [data]="m"></modulo-produto-classificacao-destaque>
                  <div class="linha-divisor"></div> 
            </div>
            <div *ngSwitchCase="'produto-destaque'">   
                  <modulo-produto-destaque-one [data]="m"></modulo-produto-destaque-one>
                  <div class="linha-divisor"></div> 
            </div>
            <div *ngSwitchCase="'produto-busca'">   
                  <modulo-produto-busca [data]="m"></modulo-produto-busca>
            </div>
            <div *ngSwitchCase="'avaliacao'">   
                  <modulo-avaliacao [data]="m"></modulo-avaliacao>
            </div>
      </div>
</div>       
