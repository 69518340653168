import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProdutoAvaliacaoModalComponent } from '../produto-avaliacao-modal/produto-avaliacao-modal.component';

declare var $:any;

@Component({
  selector: 'produto-avaliacao',
  templateUrl: './produto-avaliacao.component.html',
  styleUrls: ['./produto-avaliacao.component.scss']
})
export class ProdutoAvaliacaoComponent implements OnInit {

  @Input("data") data:any         = {};
  @Input("template") template:any = 'one';
  @ViewChild("slider") slider:any;
  @Input("produto") produto:any = {};
  public width = 0;

  constructor(
    public dialog: MatDialog
  ){}
  

  /**
   * 
   * Init slider
   * 
   */
  initSlider(){

    $(this.slider.nativeElement).slick({ 
      infinite: true,
      slidesToShow: 3,
      dots: false,  
      arrows: false,
      centerMode: false,
      centerPadding: '5px',
      responsive: [{
          breakpoint: 1224,
          settings: {
            slidesToShow: 3,  
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 2,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    }); 
    $(this.slider.nativeElement).slick('refresh'); 

  }
  /**
   * 
   * Next
   * 
   */
  prev(){ 

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }
  /**
   * 
   * First Letter
   * 
   */
  firstLetter(letter:any){

    return letter.substring(0,1);

  }
  /**
   * 
   * Open modal
   * 
   */
  openModal()
  {

    this.dialog.open(
      ProdutoAvaliacaoModalComponent,{
        width: "800px",
        data: {
          produto: this.produto
        }
      }
    );

  } 
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    
  }
  /**
   * 
   * After view
   * 
   */
  ngAfterViewInit(): void {

    this.width = window.innerWidth;
    
    if(this.template == "two"){
      this.initSlider();
    }
  }

}
