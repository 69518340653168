import { Component, Input, OnInit, ViewChild } from '@angular/core';

declare var $:any;

@Component({
  selector: 'modulo-avaliacao',
  templateUrl: './avaliacao.component.html',
  styleUrls: ['./avaliacao.component.scss']
})
export class AvaliacaoComponent implements OnInit {

  @Input("data") data:any;
  @Input("template") template = null; 
  @Input("classCustom") classCustom = ""; 
  @ViewChild("slider") slider:any;  
  
  constructor(){}   
  
  /***
   * 
   * Inicializa o Slider
   * 
   */
  initSlider(){

    $(this.slider.nativeElement).slick({  
      infinite: true, 
      slidesToShow: 3,     
      dots: false,
      arrows: false,
      centerMode: false,
      centerPadding: '5px',
      lazyLoad: "progressive",
      responsive: [{
          breakpoint: 1224,
          settings: {
            slidesToShow: 3, 
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 2,
        }
      },
      {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    }); 
    //$(this.slider.nativeElement).slick('refresh');  

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }
  /**
   * 
   * Next
   * 
   */
  prev(){ 

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}
  /**
   * 
   * Init
   * 
   */
  ngAfterViewInit(): void {
    this.initSlider();
  }

}
