import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProdutoItemComponent } from 'src/app/components/produto/produto-item/produto-item.component';
import { ProdutoItemTwoComponent } from 'src/app/components/produto/produto-item-two/produto-item-two.component';
import { PipeModule } from '../../pipe/pipe.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { ProdutoItemValorModule } from '../produto-item-valor/produto-item-valor.module';
import { ProdutoSearchComponent } from '../../../components/produto/produto-search/produto-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PartialModule } from '../../partial/partial.module';
import { LoaderModule } from '../../loader/loader/loader.module';
import { ComboDataSelectModule } from '../../combo/combo-data-select/combo-data-select.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { RouterModule } from '@angular/router';
import { ProdutoItemThreeComponent } from '../../../components/produto/produto-item-three/produto-item-three.component';
import { ProdutoAvaliacaoStarComponent } from 'src/app/components/produto/produto-avaliacao-star/produto-avaliacao-star.component';
import { ProdutoAvaliacaoComponent } from 'src/app/components/produto/produto-avaliacao/produto-avaliacao.component';
import { ProdutoAvaliacaoItemComponent } from 'src/app/components/produto/produto-avaliacao-item/produto-avaliacao-item.component';
import { ProdutoAvaliacaoModalComponent } from 'src/app/components/produto/produto-avaliacao-modal/produto-avaliacao-modal.component';

@NgModule({
  declarations: [
    ProdutoItemComponent,
    ProdutoItemTwoComponent,
    ProdutoItemComponent,
    ProdutoSearchComponent,
    ProdutoItemThreeComponent,
    ProdutoAvaliacaoStarComponent,
    ProdutoAvaliacaoComponent,
    ProdutoAvaliacaoItemComponent,
    ProdutoAvaliacaoModalComponent
  ],
  exports: [ 
    ProdutoItemTwoComponent,
    ProdutoItemComponent,
    ProdutoSearchComponent,
    ProdutoItemThreeComponent,
    ProdutoAvaliacaoStarComponent,
    ProdutoAvaliacaoComponent,
    ProdutoAvaliacaoItemComponent,
    ProdutoAvaliacaoModalComponent
  ],
  imports: [
    ProdutoItemValorModule,
    LazyLoadImageModule,
    PipeModule,
    PartialModule,    
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderModule,
    ComboDataSelectModule,
    RouterModule
  ]
})
export class ProdutoPartialModule { }
